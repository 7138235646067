import React, {useContext, useMemo} from "react";
import Assignment from "../../../model/assignment.interface";
import AssignedSchedule from "../../../model/assignedSchedule.interface";
import {Card, Level, Modal} from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarCheck} from "@fortawesome/free-solid-svg-icons";
import Line from "../../../model/line.interface";
import {PlanningContainer} from "../../../context/container";
import Technician from "../../../model/technician.interface";
import {DateUtils} from "../../../../Generic/libraries/dateManipulation";
import {PlanningContext} from "../../../context/PlanningContextProvider";
import useTranslation from "../../../../Generic/hooks/useTranslation";
import {TechnicianQualification} from "./technicianQualification";
import {CommentForm} from "./commentForm";
import {useComment} from "./useComment";
import {useReplacementAssignment} from "./useReplacementAssignment";
import { TechnicianForm } from "./technicianForm";

interface ReplacementModalProps {
    show: boolean,
    assignment: Assignment,
    assignedSchedule: AssignedSchedule,
    closeModal: () => void
}

const DISABLED_CLASSNAME = 'buttonDisabled'

export type ReplacementType = 'replacing' | 'replaced' | ''

export function ReplacementModal(props: ReplacementModalProps) {
    const {state, actions} = useContext(PlanningContext)
    const {trans} = useTranslation()

    const replacementType: ReplacementType = useMemo<ReplacementType>(() => {
        if (props.assignment.replaced){
            return 'replaced';
        }

        if (props.assignment.replacing){
            return 'replacing';
        }
        return '';
    }, [props.assignment])

    const {replacementAssignment, setReplacementAssignment, replacementAssignmentHasChanged} = useReplacementAssignment(props.assignment, replacementType)
    const {commentValue, setCommentValue, commentValueHasChanged} = useComment(props.assignment, replacementType)
    const showCommentForm = useMemo<boolean>(() => replacementAssignment !== null || replacementType === 'replacing', [replacementAssignment])

    const assignmentLine = useMemo<Line>(() => PlanningContainer.lineRepository.findOne(props.assignedSchedule.lineId), [props.assignedSchedule])
    const assignmentTechnician = useMemo<Technician>(() => PlanningContainer.technicianRepository.findOne(props.assignment.technicianId), [props.assignment])

    /**
     * Is saveable when :
     * 1. has a replacing Assignment
     * 2. the value has changed
     * 3. comment value is not ''
     * 4. commentValue has changed
     */
    const isSaveable = useMemo<boolean>(() => {
        return [
            replacementAssignment !== null,
            replacementAssignmentHasChanged || commentValueHasChanged,
            commentValue !== '',
        ].every(a => a) //all are true
    }, [replacementAssignment, commentValue])


    function getButtonTitle(type){
        switch (type) {
            case 'onSave':
                return trans('planning.assignment.modal.form.button.save');
            case 'onCancel':
                return trans('planning.assignment.modal.form.button.cancel');
            case 'onRemoveReplacement':
                return trans('planning.assignment.modal.form.button.remove');
        }
    }

    const saveReplacement = () => {
        if (!isSaveable) {
            return 
        }
        
        if (replacementAssignment !== null) {
            actions.saveReplacement(props.assignment, replacementAssignment, commentValue)
        } else {
            deleteReplacement()
        }
    }

    const deleteReplacement = () => {
        actions.deleteReplacement(props.assignment)
    }

    return (
        <Modal show={props.show} onClose={props.closeModal} closeOnBlur={true} showClose={false}>
            <Modal.Card>
                <Modal.Card.Header onClose={props.closeModal}>
                    <Modal.Card.Title>
                        <FontAwesomeIcon icon={faCalendarCheck}/> {assignmentLine.name} - {assignmentTechnician.trigram} - {DateUtils.getDateDisplay(props.assignedSchedule.day, 'd/m')}
                    </Modal.Card.Title>
                </Modal.Card.Header>
                <Modal.Card.Body id='assignmentModalBody'>
                    <Card>
                        <Card.Content>
                            {!state.incompleteSolution?(
                                <div className="modal__content">
                                    <div className="technician__form__part">
                                        <TechnicianForm assignment={props.assignment} replacementType={replacementType} assignmentReplacement={replacementAssignment} changeValue={setReplacementAssignment} />
                                        <TechnicianQualification  technician={assignmentTechnician}/>
                                    </div>
                                    <CommentForm show={showCommentForm} replacementType={replacementType} value={commentValue} changeValue={(event) => setCommentValue(event.target.value)} />
                                </div>
                            ):(
                                <Level>
                                    {trans('planning.assignment.modal.form.incompleteSolution')}
                                </Level>
                            )}

                        </Card.Content>
                        {!state.incompleteSolution?(
                            <Card.Footer>
                                {state.isLogged?
                                    <Card.Footer.Item className={!isSaveable ? DISABLED_CLASSNAME : ''} onClick={saveReplacement}>{getButtonTitle('onSave')}</Card.Footer.Item>
                                    :''}
                                {(props.assignment.replaced && state.isLogged)?
                                    <Card.Footer.Item onClick={deleteReplacement}>{getButtonTitle('onRemoveReplacement')}</Card.Footer.Item>
                                    :''}
                                <Card.Footer.Item onClick={props.closeModal}>{getButtonTitle('onCancel')}</Card.Footer.Item>
                            </Card.Footer>
                        ):''}
                    </Card>
                </Modal.Card.Body>
            </Modal.Card>
        </Modal>
    )
}