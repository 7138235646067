import {Panel} from "react-bulma-components";
import {DateUtils} from "../../../../Generic/libraries/dateManipulation";
import React from "react";

export function DayHeader({day}: {day: Date}){
    return (
        <Panel.Block className="is-size-5" key={DateUtils.getDayIndexFromDay(day)}>
            {DateUtils.getDateDisplay(day, "D. d M")}
        </Panel.Block>
    )
}