import React, {useContext, useEffect, useRef, useState} from "react";
import {PlanningContext} from "../../context/PlanningContextProvider";
import {Box, Button, Loader, Tag} from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";
import useTranslation from "../../../Generic/hooks/useTranslation";
import {ChangeObjectType, ChangeType} from "../../model/planningChange.interface";
import {getChangesForObjectAndType} from "../../repository/State/stateChangesRepository";
import {classNames} from "../../../Generic/libraries/style";

export default function ChangeMenu () {
    const {state, actions} = useContext(PlanningContext);
    const {trans} = useTranslation();
    const [openFull, setOpenFull] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false)

    useEffect(() => {
        if (state.changes.length === 0 || state.errors !== null) {
            setIsSaving(false)
        }
    }, [state])

    const saveChanges = () => {
        setIsSaving(true)
        actions.persistChanges()
        setOpenFull(!openFull)
    }


    return (
        <div id="planningMenuChange" className={openFull ? 'openFull' : ''}>
            <div className="title" onClick={() => setOpenFull(!openFull)}>
                {isSaving ?
                    <Loader />
                    : <FontAwesomeIcon icon={faCheckDouble} />}
                <div>{ trans('planning.changeMenu.title') }</div>
            </div>

            <div className={classNames("content", openFull ? 'openFull' : '' )}>
                <div className="container">
                    <ChangeObjectTypeDisplay objectType={ChangeObjectType.Assigned_Schedule} />
                    <ChangeObjectTypeDisplay objectType={ChangeObjectType.Assignment} />
                    <ChangeObjectTypeDisplay objectType={ChangeObjectType.Replacement} />
                    <div className="save">
                        <Button color="success" renderAs="span" onClick={saveChanges}>
                            { trans('planning.changeMenu.button.save') }
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ChangeObjectTypeDisplay({objectType}: {objectType: ChangeObjectType}) {
    const {state} = useContext(PlanningContext);
    const {trans} = useTranslation();

    const getNbChangesForObjectAndType = (objectType: ChangeObjectType, changeType: ChangeType): number => {
        return getChangesForObjectAndType(state, objectType, changeType).length
    }

    const getTranslationForObjectType = (objectType: ChangeObjectType): string =>{
        let toTranslate = ''
        switch (objectType) {
            case ChangeObjectType.Assigned_Schedule:
                toTranslate = 'planning.changeMenu.schedule.title'
                break
            case ChangeObjectType.Assignment:
                toTranslate = 'planning.changeMenu.assignments.title'
                break
            case ChangeObjectType.Replacement:
                toTranslate = 'planning.changeMenu.replacement.title'
                break
        }
        return trans(toTranslate)
    }

    return (
        <div className="changeObject">
            <div className="objectType">
                {getTranslationForObjectType(objectType)}
            </div>
            <div className="changeTypes">
                <div className="tags has-addons">
                    <Tag color="success">
                        { trans('planning.changeMenu.creation.title') }
                    </Tag>
                    <Tag>{getNbChangesForObjectAndType(objectType, ChangeType.Creation)}</Tag>
                </div>
                <div className="tags has-addons">
                    <Tag color="warning">
                        { trans('planning.changeMenu.update.title') }
                    </Tag>
                    <Tag>{getNbChangesForObjectAndType(objectType, ChangeType.Update)}</Tag>
                </div>
                <div className="tags has-addons">
                    <Tag color="danger">
                        { trans('planning.changeMenu.deletion.title') }
                    </Tag>
                    <Tag>{getNbChangesForObjectAndType(objectType, ChangeType.Deletion)}</Tag>
                </div>
            </div>
        </div>
    )
}