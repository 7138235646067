import React, {ChangeEventHandler, useContext} from "react";
import {PlanningContext} from "../../../context/PlanningContextProvider";
import {ReplacementType} from "./replacementModal";
import {Form} from "react-bulma-components";
import useTranslation from "../../../../Generic/hooks/useTranslation";

interface CommentFormProps {
    show: boolean,
    replacementType: ReplacementType,
    value: string,
    changeValue: ChangeEventHandler<HTMLTextAreaElement>
}

export function CommentForm(props: CommentFormProps) {
    const {state} = useContext(PlanningContext)
    const {trans} = useTranslation()
    
    if (props.show){
        if (state.isLogged && props.replacementType !== 'replacing'){
            return (
                <div>
                    <Form.Field>
                        <Form.Label>{trans('planning.assignment.modal.form.comment.updateTitle')}</Form.Label>
                        <Form.Control>
                            <Form.Textarea name="commentValue" value={props.value} onChange={props.changeValue} placeholder={trans('planning.assignment.modal.form.comment.placeHolder')} />
                        </Form.Control>
                    </Form.Field>
                </div>
            );
        } else {
            return (
                <Form.Field>
                    <Form.Label>{trans('planning.assignment.modal.form.comment.displayTitle')}</Form.Label>
                    <div style={{whiteSpace: 'pre-wrap'}}>{props.value === ''?trans('planning.assignment.modal.form.comment.noComment'):props.value}</div>
                </Form.Field>
            )
        }
    }

    return null;
}