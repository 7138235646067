import Assignment, {getAssignmentId} from "../../../model/assignment.interface";
import {useContext} from "react";
import {PlanningContext} from "../../../context/PlanningContextProvider";
import useTranslation from "../../../../Generic/hooks/useTranslation";
import Replacement, {isReplacement} from "../../../model/replacement.interface";
import {getAssignedSchedule} from "../../../repository/State/stateAssignedSchedulesRepository";
import {PlanningContainer} from "../../../context/container";
import {Panel} from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretRight, faChevronRight, faGripLines} from "@fortawesome/free-solid-svg-icons";

export function ReplacementLine({assignment}: {assignment: Assignment}){
    const {state} = useContext(PlanningContext)
    const {trans} = useTranslation()

    if (!isReplacement(assignment.replacedReplacement)){
        return
    }
    let replacedReplacement: Replacement = assignment.replacedReplacement
    //Remplacement par soi-même pour assurer un 'remplacement à blanc' ==> l'opérateur doit s'absenter mais ne se fait pas remplacer
    let isSelfReplacement = replacedReplacement.replacing.trigram === replacedReplacement.replaced.trigram;
    const assignedSchedule = getAssignedSchedule(state, assignment.assignedScheduleId, assignment.assignedScheduleFrontId)
    const line = PlanningContainer.lineRepository.findOne(assignedSchedule.lineId)

    return (
        <Panel.Block key={'assignment-'+getAssignmentId(assignment)}>
            <div className="has-padding-left-10">
                {isSelfReplacement ?(
                    <div>{replacedReplacement.replaced.trigram} ({trans('operatorPlanning.sidePanel.comments.blankreplacement')}) <FontAwesomeIcon icon={faGripLines} /> {line.name}</div>
                ):(
                    <div>{replacedReplacement.replacing.trigram} <FontAwesomeIcon icon={faChevronRight} /> {replacedReplacement.replaced.trigram} <FontAwesomeIcon icon={faGripLines} /> {line.name}</div>
                )}

            </div>
            <div className="has-padding-left-15">
                <FontAwesomeIcon icon={faCaretRight} /> {replacedReplacement.comment}
            </div>
        </Panel.Block>
    )
}
