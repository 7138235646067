import React, {useContext, useMemo} from "react";
import {hexToRgb, lightOrDark} from "../../../Generic/libraries/colors";
import {CommonReactTable} from "../common/commonReactTable";
import {DayColumnCell} from "./dayColumnCell";
import Line from "../../model/line.interface";
import {PlanningContainer} from "../../context/container";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import {getLineIndex} from "../../../Generic/formatter/lineFormatter";
import {PlanningCell} from "./PlanningCell/planningCell";
import FullHeader from "./fullHeader";
import {findAllAssignedSchedulesForDayAndLine} from "../../repository/State/stateAssignedSchedulesRepository";
import {PlanningContext} from "../../context/PlanningContextProvider";
import useTranslation from "../../../Generic/hooks/useTranslation";
import {Column} from "react-table";

type LinePlanningTableProps = {
    switchPlanningView: () => void
}

export type PlanningColumn<T extends object = {}>  = {
    headerClassName?: string,
    cellClassName?: string,
    line?: Line
} & Column<T>;

export default function LinePlanningTable({switchPlanningView}: LinePlanningTableProps) {
    const {state} = useContext(PlanningContext);
    const {trans} = useTranslation();

    const dimensions = {
        dayColumnWidth: 30,
        dataColumnWidth: 120,
        columnBorder: 2
    }

    const tableColumns = useMemo(() => {
        let daysColumn: PlanningColumn<{day: Date}>[] = [];

        if (state.week.days.length > 0) {
            daysColumn = [
                {
                    Header: (
                        <div className='visualizationSwitcher'
                             onClick={switchPlanningView}>{trans('planning.switch.planning.view.label.fromLine')}</div>
                    ),
                    accessor: 'day',
                    headerClassName: 'cornerHeader',
                    cellClassName: 'indexCell bodyCell',
                    width: dimensions.dayColumnWidth,
                    Cell: (data) => (
                        <DayColumnCell day={data.value} />
                    ),
                }
            ]
        }

        const lines: Line[] = PlanningContainer.lineRepository.findAll('number');

        const lineColumns: PlanningColumn<{[k:string]: {assignedSchedules: AssignedSchedule[], day: Date }}>[] = lines.map((line: Line) => {
            return {
                Header: line.name,
                accessor: getLineIndex(line),
                width: dimensions.dataColumnWidth,
                line: line,
                Cell: (data) => (
                    <PlanningCell assignedSchedules={data.value.assignedSchedules} key={line.id} day={data.value.day} line={line}/>
                ),
            }
        })

        const minWidth = 600;
        let headerColumn: PlanningColumn = {
            Header: () => {
                return <FullHeader />;
            },
            id: 'topHeader',
            headerClassName: 'fullHeader',
            columns: [...daysColumn, ...lineColumns],
            minWidth: minWidth,
            width: Math.max(minWidth,(dimensions.dayColumnWidth)) + (lineColumns.length * dimensions.dataColumnWidth) + ((lineColumns.length) * dimensions.columnBorder)
        };

        return [headerColumn];
    }, [state])

    const tableData = useMemo(() => {
        return state.week.days.map( (day) => {
            let data = {
                day
            };

            PlanningContainer.lineRepository.findAll().forEach((l: Line) => {
                data[getLineIndex(l)] = {
                    assignedSchedules: findAllAssignedSchedulesForDayAndLine(state, day, l),
                    day: day
                }
            })

            return data;
        });
    }, [state])

    const getHeaderProps = (column) => {
        const columnId = column.originalId ?? column.id;

        if (columnId === 'topHeader' || columnId === 'day') {
            return [{
                className: column.headerClassName,
                style: {
                    width: column.width
                }
            }];
        } else {
            const {r,g,b} = hexToRgb(column.line.color);
            const backgroundColor = 'rgba('+r+','+g+','+b+', 0.7)';
            let textColor = 'inherit';
            if (lightOrDark(backgroundColor) === 'dark'){
                textColor = '#fff';
            }

            return {
                className: 'dataHeader',
                style: {
                    background: 'rgba('+r+','+g+','+b+', 0.7)',
                    color: textColor,
                    width: column.width
                }
            }
        }
    }

    const getCellProps = (cell) => {
        if (cell.column.id === 'day') {
            return [{
                className: cell.column.cellClassName,
                style: {
                    width: cell.column.width
                }
            }]
        } else {
            const {r,g,b} = hexToRgb(cell.column.line.color);
            return [{
                className: 'valueCell bodyCell',
                style: {
                    background: 'rgba('+r+','+g+','+b+', 0.33)'
                }
            }];
        }
    }

    return (
        <CommonReactTable
            columns={tableColumns}
            data={tableData}
            tableId="planningTable"
            getHeaderProps={getHeaderProps}
            getCellProps={getCellProps}
        />
    )
}
