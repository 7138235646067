import {Panel} from "react-bulma-components";
import {ReplacementBlock} from "./replacementBlock";
import {create_UUID} from "../../../../Generic/libraries/uuid";
import {useContext} from "react";
import {ReplacementData} from "./operatorPlanningSidePanel";
import {PlanningContext} from "../../../context/PlanningContextProvider";
import useTranslation from "../../../../Generic/hooks/useTranslation";
import Assignment from "../../../model/assignment.interface";
import {findAllAssignedSchedulesForDay} from "../../../repository/State/stateAssignedSchedulesRepository";
import AssignedSchedule from "../../../model/assignedSchedule.interface";

export function CommentsPanel() {
    const {state} = useContext(PlanningContext)
    const {trans} = useTranslation()

    let replacements: ReplacementData[] = [];
    state.week.days.forEach((day: Date) => {
        let existsReplacements = false;
        let dayAssigmentReplaced: Assignment[] = [];
        findAllAssignedSchedulesForDay(state, day).forEach((assignedSchedule: AssignedSchedule) => {
            assignedSchedule.assignments.forEach((assignment: Assignment) => {
                if (assignment.replaced){
                    dayAssigmentReplaced.push(assignment);
                    existsReplacements = true;
                }
            })
        })
        if (existsReplacements){
            replacements.push({
                day,
                assignments: dayAssigmentReplaced
            })
        }
    });

    return (
        <Panel>
            <Panel.Header key="panel-heading">
                {trans('operatorPlanning.sidePanel.comments.title')}
            </Panel.Header>

            {replacements.map((replacementData: ReplacementData) => <ReplacementBlock key={create_UUID()} replacementData={replacementData}/>)}

            {replacements.length === 0 && (
                <Panel.Block className="center">
                    {trans('operatorPlanning.sidePanel.comments.nocomments')}
                </Panel.Block>
            )}
        </Panel>
    )
}
