import {useContext, useState, useEffect} from "react";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {Notification} from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PlanningContext} from "./context/PlanningContextProvider";
import PlanningDisplay from "./components/planningDisplay";
import useTranslation from "../Generic/hooks/useTranslation";

export default function Planning() {
    const {state,error} = useContext(PlanningContext)
    const {trans} = useTranslation()

    const [displayBackendError, setDisplayBackendError] = useState<boolean>(error !== null)
    const [displayStateErrors, setDisplayStateErrors] = useState(state.errors !== null)

    useEffect(() => setDisplayBackendError(error !== null), [error])
    useEffect(() => {
        setDisplayStateErrors(state.errors !== null)
    }, [state.errors])

    const clearErrors = () => {
        setDisplayBackendError(false)
        setDisplayStateErrors(false)
    }

    return (
        <div id="app-content" className="is-clearfix">
          <>
              { (!state.isLogged && state.dayUpdated )? (
                  <Notification color='warning'><FontAwesomeIcon icon={faExclamationTriangle} className="mr-2"/> {trans('planning.notification.warning.dayChanges')} </Notification>
              ) : ''}
              {displayBackendError || displayStateErrors ? (
                  <Notification color='danger' className="pointer" light={true} onClick={clearErrors}>
                      {displayBackendError ? (
                          <div>
                              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2"/> {error.message} | Please reload the page. Changes could have been saved even with the error.
                          </div>
                      ) : null}
                      {displayStateErrors ? state.errors?.map((e: Error, index: number) => <div key={"e"+index} className="mb-2"><FontAwesomeIcon icon={faExclamationTriangle} className="mr-2"/>{e.message}</div>) : null}
                  </Notification>
              ) : null}
              <PlanningDisplay />
          </>
        </div>
    )
}
