import {useContext, useMemo} from "react";
import AssignedSchedule, {isSameAssignedSchedule} from "../../../model/assignedSchedule.interface";
import {Form, Tag} from "react-bulma-components";
import Technician from "../../../model/technician.interface";
import {PlanningContext} from "../../../context/PlanningContextProvider";
import {PlanningContainer} from "../../../context/container";
import {DateUtils} from "../../../../Generic/libraries/dateManipulation";
import {assignedScheduleRepository} from "../../../repository/assignedScheduleRepository";
import {SameConstraint} from "../../../../Generic/validation/constraint/sameConstraint";
import {NotSameConstraint} from "../../../../Generic/validation/constraint/notSameConstraint";
import {AssignedScheduleTechnicianAvailabilityConstraints} from "./AssignedScheduleModalEditForm";
import useTranslation from "../../../../Generic/hooks/useTranslation";
import useValidator from "../../../../Generic/hooks/useValidator";
import TechnicianTag from "../technicianTag";
import { SamePlanningModeForTechnicianAndScheduleConstraint } from "../../../validation/constraints/SamePlanningModeForTechnicianAndScheduleConstraint";

export function AssignedScheduleAlreadyAssignedTrigram({assignedSchedule}: {assignedSchedule: AssignedSchedule}) {
    const {state} = useContext(PlanningContext)
    const {trans} = useTranslation()
    const {validate} = useValidator()

    const alreadyAssignedTechniciansForAssignedSchedule = useMemo<Technician[]>(() => {
        return state.assignedSchedules.reduce((alreadyAssignedTechniciansInOtherAssignedSchedules: Technician[], a: AssignedSchedule): Technician[] => {
            //check if the looked up assignedSchedule is aligned with the assigned Schedule
            const consistentAssignedSchedule : boolean = validate([
                new SameConstraint(assignedSchedule.day, a.day, DateUtils.dateEquals),
                new NotSameConstraint(a, assignedSchedule, isSameAssignedSchedule),
            ])

            if (consistentAssignedSchedule) {
                const assignedScheduleTechnicians = assignedScheduleRepository.getAssignedTechnicians(a)
                const availableTechnicians = assignedScheduleTechnicians.filter((t: Technician) => {
                    return validate([
                        ...AssignedScheduleTechnicianAvailabilityConstraints(assignedSchedule, t),
                        new SamePlanningModeForTechnicianAndScheduleConstraint(assignedSchedule.scheduleId, t)
                    ]);
                })

                // return the technicians which would be available if not already assigned
                return [...alreadyAssignedTechniciansInOtherAssignedSchedules, ...availableTechnicians]
            } else {
                return [...alreadyAssignedTechniciansInOtherAssignedSchedules]
            }
        }, [])
            .sort((a: Technician, b:Technician) => {
                if (a.trigram < b.trigram) {
                    return -1
                } else {
                    return 1
                }
            })
    },[assignedSchedule])

    return (
        <>
            <Form.Label>{trans('planning.assignedSchedule.modal.form.otherTechnicians.title')}</Form.Label>
            <Form.Control>
                { /* List of non-available technicians for AssignedSchedule */ }
                <Tag.Group>
                    {alreadyAssignedTechniciansForAssignedSchedule.map((technician: Technician) =>{

                        return (
                          <TechnicianTag
                            key={technician.id}
                            technician={technician}
                            additionalClass={'is-italic'}
                            line={PlanningContainer.lineRepository.findOne(assignedSchedule.lineId)}
                          />
                        )
                    }, this)}

                </Tag.Group>
            </Form.Control>
        </>
    );
}
