import { map } from "lodash";
import {Constraint} from "../constraint.interface";

export class OrConstraint implements Constraint {
    _constraints: Array<Constraint>
    errorMessage: string;

    constructor(constraints: Array<Constraint>, errorMessage: string | null = null) {
        this._constraints = constraints
        this.errorMessage = errorMessage ?? 'Incorrect statement'
    }
    isValid(): boolean {
        return  this._constraints.map((c:Constraint) => c.isValid()).filter(c => c).length > 0;
    }

}