import {useContext, useMemo, useRef, useState} from "react";
import Assignment, {isSameAssigment} from "../../../model/assignment.interface";
import {Tag} from "react-bulma-components";
import {PlanningContext} from "../../../context/PlanningContextProvider";
import {DateUtils} from "../../../../Generic/libraries/dateManipulation";
import {TechnicianService} from "../../../model/Service/technicianService";
import AssignedSchedule, {isSameAssignedSchedule} from "../../../model/assignedSchedule.interface";
import {PlanningContainer} from "../../../context/container";
import {useDrag, useDrop} from "react-dnd";
import {ReplacementModal} from "../ReplacementModal/replacementModal";
import {BooleanConstraint} from "../../../../Generic/validation/constraint/BooleanConstraint";
import {NotSameConstraint} from "../../../../Generic/validation/constraint/notSameConstraint";
import {SameConstraint} from "../../../../Generic/validation/constraint/sameConstraint";
import useValidator from "../../../../Generic/hooks/useValidator";
import { NotSamePlanningModeForTechnicianAndScheduleConstraint } from "../../../validation/constraints/NotSamePlanningModeForTechnicianAndScheduleConstraint";
import {
    SamePlanningModeForTechnicianAndScheduleConstraint
} from "../../../validation/constraints/SamePlanningModeForTechnicianAndScheduleConstraint";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { TechnicianIsTrainedOrInTrainingForAssignedScheduleConstraint } from "../../../validation/constraints/TechnicianIsTrainedOrInTrainingForAssignedSchedule.constraint";

type PlanningCellAssignmentProps = {
    assignment: Assignment,
    assignedSchedule: AssignedSchedule
}

export type AssignmentDragAndDropItemType = {
    assignment: Assignment,
    assignedSchedule: AssignedSchedule,
}

export function PlanningCellAssignment(props: PlanningCellAssignmentProps) {
    const {state, actions} = useContext(PlanningContext)
    const {validate} = useValidator()

    const [showModal, setShowModal] = useState<boolean>(false)
    const currentLine = PlanningContainer.lineRepository.findOne(props.assignedSchedule.lineId)
    const currentTechnician = PlanningContainer.technicianRepository.findOne(props.assignment.technicianId)

    const dropForSwap = (fromAssignment: Assignment, fromAssignedSchedule: AssignedSchedule) => {
        actions.swapAssignments(fromAssignment, fromAssignedSchedule, props.assignment, props.assignedSchedule)
    }

    const clickOnTechnician = () => {
        setShowModal(true)
    }

    const ref = useRef(null)
    const [,drag] = useDrag<AssignmentDragAndDropItemType, any, any>({
        type: 'assignment',
        item: {assignment: props.assignment, assignedSchedule: props.assignedSchedule},
        canDrag: _monitor => {
            return state.isLogged;
        }
    });

    const [{ didDrop, draggedItem, canDrop }, drop] = useDrop<AssignmentDragAndDropItemType, any, any>({
        accept: 'assignment',
        canDrop: (item, _monitor) => {
            /**
             * can drop if
             * 1. user is logged
             * 2. both target and dropped item are not the same assignment
             * 3. both target and dropped item are not the same assignedSchedule
             * 4. both target and dropped item have the same date
             * 5. dropped technician is trained or in training for the target Line
             * 6. target technician (= currentTechnician) is trained or in training for the dropped line
             * 7. the dropped technician has the same planning mode as the target schedule
             * 8. the target technician ( = currentTechnician) has the same planning mode as the dropped schedule
             */
            return validate([
                new BooleanConstraint(state.isLogged),
                new NotSameConstraint(props.assignment, item.assignment, isSameAssigment),
                new NotSameConstraint(props.assignedSchedule, item.assignedSchedule, isSameAssignedSchedule),
                new SameConstraint(props.assignedSchedule.day, item.assignedSchedule.day, DateUtils.dateEquals),
                new TechnicianIsTrainedOrInTrainingForAssignedScheduleConstraint(item.assignment.technicianId, props.assignedSchedule),
                new TechnicianIsTrainedOrInTrainingForAssignedScheduleConstraint(currentTechnician, item.assignedSchedule),
                new SamePlanningModeForTechnicianAndScheduleConstraint(props.assignedSchedule.scheduleId, item.assignment.technicianId),
                new SamePlanningModeForTechnicianAndScheduleConstraint(item.assignedSchedule.scheduleId, currentTechnician)
            ])
        },
        drop: (item, _monitor) => {
            dropForSwap(item.assignment, item.assignedSchedule);
        },
        collect: monitor => ({
            canDrop: monitor.canDrop(),
            draggedItem: monitor.getItem(),
            didDrop: monitor.didDrop(),
        })
    })

    //needed for allowing and drag and drop on the same instance
    drag(drop(ref));

    const isNotSamePlanningMode = useMemo<boolean>(() => {
        return validate([new NotSamePlanningModeForTechnicianAndScheduleConstraint(props.assignedSchedule.scheduleId, props.assignment.technicianId)])
    }, [])

    const getAllClassNames = () => {
        let name = ''
        if (props.assignment.changed){
            name += " is-updated";
        }
        if (!state.isLogged && DateUtils.isToday(new Date(props.assignment.updatedAt))){
            name += ' is-updated';
        }
        if (props.assignment.replaced || props.assignment.replacing){
            name += ' has-background-grey-lighter';
        }
        if (TechnicianService.isTechnicianQualifiedForLine(currentTechnician, currentLine)){
            name += ' qualifiedTechnician';
        }
        if (TechnicianService.isTechnicianInQualificationForLine(currentTechnician, currentLine)){
            name += ' inQualificationTechnician'
        }
        if (TechnicianService.isTechnicianInTrainingForLine(currentTechnician, currentLine)){
            name += ' inTrainingTechnician'
        }
        if (isNotSamePlanningMode) {
            name += ' notSamePlanningTechnician'
        }
        if (!currentTechnician.active) {
            name += ' inactiveTechnician'
        }

        return name
    }

    let className = "scheduleTrigram" + getAllClassNames();

    const dropAvailableClassname = () => {
        if (draggedItem === null || didDrop) {
            return '';
        }
        if (canDrop) {
            return ' canDrop';
        } else if (state.isLogged){
            return ' noDrop';
        }
    }

    return (
        <span ref={ref}>
            <Tag className={className+dropAvailableClassname()} onClick={clickOnTechnician}>{isNotSamePlanningMode ? (<FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" />):null} {currentTechnician.trigram}</Tag>
            {showModal ? (
                <ReplacementModal show={showModal} assignment={props.assignment} assignedSchedule={props.assignedSchedule} closeModal={() => setShowModal(false)} />
            ) : ''}
        </span>
    )
}
