import AssignedSchedule from "../../../model/assignedSchedule.interface";
import {useContext, useMemo} from "react";
import {PlanningContext} from "../../../context/PlanningContextProvider";
import Technician from "../../../model/technician.interface";
import {Form, Tag} from "react-bulma-components";
import Assignment from "../../../model/assignment.interface";
import {PlanningContainer} from "../../../context/container";
import {TechnicianService} from "../../../model/Service/technicianService";
import useTranslation from "../../../../Generic/hooks/useTranslation";
import {
    NotSamePlanningModeForTechnicianAndScheduleConstraint
} from "../../../validation/constraints/NotSamePlanningModeForTechnicianAndScheduleConstraint";
import useValidator from "../../../../Generic/hooks/useValidator";
import TechnicianTag from "../technicianTag";

interface AssignedScheduleNonSamePlanningModeTrigramsProps {
    assignedSchedule: AssignedSchedule,
    onTechnicianClick: (t: Technician) => void,
    availableTechnicians: Technician[]
}

export function AssignedScheduleNonSamePlanningModeTrigrams({assignedSchedule, onTechnicianClick, availableTechnicians}: AssignedScheduleNonSamePlanningModeTrigramsProps) {
    const {state} = useContext(PlanningContext)
    const {trans} = useTranslation()
    const {validate} = useValidator()

    const toDisplayTechnicians = useMemo<Technician[]>(() => {
        return availableTechnicians.filter((t: Technician) => {
            return validate([
                new NotSamePlanningModeForTechnicianAndScheduleConstraint(assignedSchedule.scheduleId, t)
            ])
        })
    }, [availableTechnicians, assignedSchedule])

    return (
        <>
            <Form.Label className="mt-3">{trans('planning.assignedSchedule.modal.form.nonSamePlanningTechnicians.title')}</Form.Label>
            <Form.Control>
                { /* List of available technicians but without the same planning for AssignedSchedule */ }
                <Tag.Group>
                    {toDisplayTechnicians.map((technician: Technician) =>{
                        let isAssigned = assignedSchedule.assignments.filter(tempAssignment => {return tempAssignment.technicianId === technician.id}).length > 0;
                        return (
                            <TechnicianTag
                              key={technician.id}
                              technician={technician}
                              onSelectTechnician={onTechnicianClick}
                              additionalClass={'is-italic is-clickable'}
                              line={PlanningContainer.lineRepository.findOne(assignedSchedule.lineId)}
                              isAssigned={isAssigned}
                            />
                        )
                    }, this)}

                </Tag.Group>
            </Form.Control>
        </>
    )
}