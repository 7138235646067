import {useContext, useState} from "react";
import {getDayColumnDisplay} from "../../../Generic/formatter/dayFormatter";
import {DeleteDayModal} from "./deleteDayModal";
import {PlanningContext} from "../../context/PlanningContextProvider";

interface DayColumnCellProps {
    day: Date
}

export function DayColumnCell ({day}: DayColumnCellProps) {
    const {actions} = useContext(PlanningContext)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

    const onDeleteDayModalConfirm = () => {
        setShowDeleteModal(!showDeleteModal)
        actions.deleteDayAssignedSchedules(day);
    }

    return (
        <div onClick={() => setShowDeleteModal(!showDeleteModal)}>
            {getDayColumnDisplay(day)}
            <DeleteDayModal
                show={showDeleteModal}
                day={day}
                onDelete={onDeleteDayModalConfirm}
                onClose={() => setShowDeleteModal(!showDeleteModal)}
            />
        </div>
    );
}
