import React from 'react';
import ReactDOM from 'react-dom';

import '../css/planning.scss';

import PlanningContextProvider from "./Planning/context/PlanningContextProvider";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import Planning from "./Planning/planning";
import {PlanningHeader} from "./Planning/components/planningHeader";

const appElement = document.getElementById('app')

const App = () => {
    const weekYear = appElement.dataset.weekyear
    const weekNumber = appElement.dataset.weeknumber
    return (
        <PlanningContextProvider weekYear={weekYear} weekNumber={weekNumber}>
            <DndProvider backend={HTML5Backend}>
                <div className='appContainer'>
                    <PlanningHeader />
                    <Planning />
                </div>
            </DndProvider>
        </PlanningContextProvider>
    )
}

ReactDOM.render(<App />, appElement);
