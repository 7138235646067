import LinePlanningTable from "./LinePlanningTable";
import {PlanningTableLegend} from "./planningTableLegend";

type LinePlanningProps = {
    switchPlanningView: () => void
}

export default function LinePlanning(props: LinePlanningProps) {
    return (
        <>
            <LinePlanningTable switchPlanningView={props.switchPlanningView} />
            <PlanningTableLegend />
        </>
    )
}
