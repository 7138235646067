
import React, {useContext} from "react";
import AssignedSchedule from "../../../model/assignedSchedule.interface";
import {PlanningContext} from "../../../context/PlanningContextProvider";
import {NewPlanningCellSchedule} from "./newPlanningCellSchedule";
import Line from "../../../model/line.interface";
import {PlanningCellAssignedSchedule} from "./planningCellAssignedSchedule";
import { sortByFunction } from "../../../../Generic/libraries/sort";
import { PlanningContainer } from "../../../context/container";

export type PlanningCellProps = {
    assignedSchedules: AssignedSchedule[],
    day: Date,
    line: Line
}
export function PlanningCell ({assignedSchedules, day, line}: PlanningCellProps) {
    const {state} = useContext(PlanningContext);

    return (
        <div className="PlanningCellContent">
            {sortByFunction(assignedSchedules, (a: AssignedSchedule) => PlanningContainer.scheduleRepository.findOne(a.scheduleId).startTime) // sort by schedule start time
                .map((assignedSchedule: AssignedSchedule) => (
                <PlanningCellAssignedSchedule key={assignedSchedule.id ?? assignedSchedule.frontId} assignedSchedule={assignedSchedule} />
            ))}

            {state.isLogged?(
                <NewPlanningCellSchedule  day={day} line={line}/>
            ):""}
        </div>
    )
}