import Technician from "../../model/technician.interface";
import {Tag} from "react-bulma-components";
import {TechnicianService} from "../../model/Service/technicianService";
import {classNames} from "../../../Generic/libraries/style";
import Line from "../../model/line.interface";

type TechnicianTagProps = {
  technician: Technician,
  line? : Line,
  isAssigned?: boolean,
  additionalClass?: string,
  onSelectTechnician?: (technician: Technician) => void
}
export default function TechnicianTag({technician, line = undefined, additionalClass = undefined, isAssigned = false, onSelectTechnician = undefined}: TechnicianTagProps) {

  let color: string | null = isAssigned ? 'success' : null;

  let qualifiedForLine = line ? TechnicianService.isTechnicianQualifiedForLine(technician, line) : false;
  let inTrainingForLine = line ? TechnicianService.isTechnicianInTrainingForLine(technician, line) : false;
  let inQualificationForLine = line ? TechnicianService.isTechnicianInQualificationForLine(technician, line) : false;

  let className: string = classNames(
    isAssigned ? '' : 'scheduleTrigram',
    qualifiedForLine ? 'qualifiedTechnician' : '',
    inTrainingForLine ? 'inTrainingTechnician' : '',
    inQualificationForLine ? 'inQualificationTechnician' : '',
    additionalClass ? additionalClass : ''
    )

  return (
    <Tag
      color={color}
      onClick={() => onSelectTechnician ? onSelectTechnician(technician) : null}
      className={className}>
      {technician.trigram}
    </Tag>
  )
}