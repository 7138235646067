import { PlanningContext } from "../../context/PlanningContextProvider";
import { OperatorPlanningTable } from "./operatorPlanningTable";
import { OperatorPlanningSidePanel } from "./SidePanel/operatorPlanningSidePanel";
import {useContext, useMemo, useState} from "react";
import {PlanningContainer} from "../../context/container";

interface OperatorPlanningProps {
    switchPlanningView: () => void;
};

export enum OperatorPlanningViewType {
    FULL = 'full',
    INTERIM = 'interim'
}

export function PlanningOperatorView({switchPlanningView}: OperatorPlanningProps) {
    const {state} = useContext(PlanningContext);
    const [viewType, setViewType] = useState<OperatorPlanningViewType>(OperatorPlanningViewType.FULL)

    const technicians = useMemo(() => {
        switch (viewType) {
            case OperatorPlanningViewType.FULL:
                return PlanningContainer.technicianRepository.findAll();
            case OperatorPlanningViewType.INTERIM:
                return PlanningContainer.technicianRepository.findAllInterim();
        }
    }, [state, viewType])

    return (
        <div className="is-flex is-align-items-flex-start">
            <OperatorPlanningTable switchPlanningView={switchPlanningView} technicians={technicians}/>
            {state.initialized ? <OperatorPlanningSidePanel view={viewType} setViewType={setViewType}/> : ""}
        </div>
    );
}
