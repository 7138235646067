import {Constraint} from "../../../Generic/validation/constraint.interface";
import Technician from "../../model/technician.interface";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import {PlanningContainer} from "../../context/container";
import {TechnicianService} from "../../model/Service/technicianService";
import {isNumber} from "lodash";

export class TechnicianIsTrainedOrInTrainingForAssignedScheduleConstraint implements Constraint {
    _technician: Technician
    _assignedSchedule: AssignedSchedule
    errorMessage: "The technician is not qualified for the line of the Assigned Schedule"

    constructor(technician: Technician|number, assignedSchedule: AssignedSchedule) {
        if (isNumber(technician)) {
            technician = PlanningContainer.technicianRepository.findOne(technician)
        }

        this._technician = technician
        this._assignedSchedule = assignedSchedule
    }

    isValid(): boolean {
        const line = PlanningContainer.lineRepository.findOne(this._assignedSchedule.lineId)
        return TechnicianService.isTechnicianTrainedOrInTrainingForLine(this._technician, line)
    }
}
