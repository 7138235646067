import {Panel} from "react-bulma-components";
import React, {useContext} from "react";
import useTranslation from "../../../../Generic/hooks/useTranslation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretRight, faChevronRight, faGripLines} from "@fortawesome/free-solid-svg-icons";
import {DateUtils} from "../../../../Generic/libraries/dateManipulation";
import {PlanningContext} from "../../../context/PlanningContextProvider";
import {
    findAllAssignedSchedulesForDay,
    getAssignedSchedule
} from "../../../repository/State/stateAssignedSchedulesRepository";
import AssignedSchedule from "../../../model/assignedSchedule.interface";
import Assignment, {getAssignmentId} from "../../../model/assignment.interface";
import {create_UUID} from "../../../../Generic/libraries/uuid";
import Replacement, {isReplacement} from "../../../model/replacement.interface";
import {PlanningContainer} from "../../../context/container";
import {OperatorPlanningViewType} from "../planningOperatorView";
import {Switch} from "../../../../Generic/components/switch";
import {ReplacementBlock} from "./replacementBlock";
import {CommentsPanel} from "./commentsPanel";
import {ActionPanel} from "./actionPanel";

export type ReplacementData = {assignments: Assignment[], day: Date}

interface OperatorPlanningSidePanelProps {
    view: OperatorPlanningViewType
    setViewType: (type: OperatorPlanningViewType) => void
}

export function OperatorPlanningSidePanel({view, setViewType}: OperatorPlanningSidePanelProps) {
    const {state} = useContext(PlanningContext)

    return (
        <div id="commentsPanel">
            {state.isLogged ? (
                <ActionPanel view={view} handleChangeView={setViewType} />
            ):null}
            
            <CommentsPanel />
        </div>
    );
}
