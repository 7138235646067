import {useContext} from "react";
import Header, {MenuEntry} from "../../Generic/components/header";
import {PlanningContext} from "../context/PlanningContextProvider";
import ChangeMenu from "./ChangeMenu/changeMenu";
import useRouting from "../../Generic/hooks/useRouting";

export function PlanningHeader() {
    const {state} = useContext(PlanningContext)
    const {generate} = useRouting()

    const menuEntry: MenuEntry = {
        display: "Demands",
        url: generate('demands_index'),
        shouldBeLogged: true
    };

    return (
        <Header currentPageName={"Planning"} menuEntries={[menuEntry]} isLogged={state.isLogged} initialized={state.initialized}>
            {state.isLogged && state.changes.length > 0 ? <ChangeMenu /> : null}
        </Header>
    )
}