import React, {useContext} from 'react';
import { Navbar } from "react-bulma-components";
import '../../../css/header.scss';

import useRouting from "../hooks/useRouting";
import ChangeMenu from "../../Planning/components/ChangeMenu/changeMenu";
import {PlanningContext} from "../../Planning/context/PlanningContextProvider";

export interface MenuEntry {
    display: string,
    url: string,
    shouldBeLogged: boolean
}

type HeaderPropsType =  {
    currentPageName: string,
    menuEntries: [MenuEntry]
    isLogged: boolean,
    initialized: boolean,
    children?: JSX.Element
}

export default function Header (props: HeaderPropsType) {
    const {generate} = useRouting()

    return (
        <Navbar color='white' fixed='top'>
            <Navbar.Brand>
                <Navbar.Item renderAs="a" href="#">
                    <div className="is-size-3">Packaging {props.currentPageName}</div>

                </Navbar.Item>
            </Navbar.Brand>
            <Navbar.Menu>
                <Navbar.Container align='left'>
                    {props.menuEntries.map((m: MenuEntry) => {
                        if (props.isLogged === m.shouldBeLogged) {
                            return <Navbar.Item key={m.url} href={m.url}>{m.display}</Navbar.Item>
                        }
                        return null
                    })}
                </Navbar.Container>
                { props.children ? props.children : null}
                { props.initialized && props.isLogged ? (
                    <Navbar.Container align="right">
                        <Navbar.Item href={ generate('easyadmin') }>Admin</Navbar.Item>
                        <Navbar.Item href={ generate('app_logout') }>Logout</Navbar.Item>
                    </Navbar.Container>
                ) : props.initialized && !props.isLogged ? (
                    <Navbar.Container align="right">
                        <Navbar.Item href={ generate('app_login') }>Login</Navbar.Item>
                    </Navbar.Container>
                ): ''}
            </Navbar.Menu>
        </Navbar>
    );
}