import React, {useContext, useEffect, useState} from "react";
import LinePlanning from "./LinePlanning/LinePlanning";
import {PlanningContext} from "../context/PlanningContextProvider";
import {PlanningOperatorView} from "./OperatorPlanning/planningOperatorView";

export  default function PlanningDisplay () {
    const {state} = useContext(PlanningContext)
    const [displayLinePlanning, setDisplayLinePlanning] = useState(state.isLogged)

    useEffect(() => {
        setDisplayLinePlanning(state.isLogged)
    }, [state.isLogged])

    const switchDisplay = () => {
        setDisplayLinePlanning(!displayLinePlanning)
    }

    return (
        <>
            {displayLinePlanning ? <LinePlanning switchPlanningView={switchDisplay} /> : <PlanningOperatorView switchPlanningView={switchDisplay}/>}
        </>
    )
}
