import {Constraint} from "../constraint.interface";
import {ComparatorFunction, createTrueComparatorFunction} from "../comparatorFunctionFactory";

export class InArrayConstraint<T> implements Constraint {
    _array : T[]
    _element: T
    _comparator: ComparatorFunction<T>
    errorMessage: string;

    constructor(arr: T[], element: T, comparator: string|ComparatorFunction<T>|null =  null, errorMessage: string = '') {
        this._array = arr
        this._element = element
        this.errorMessage = errorMessage
        this._comparator = createTrueComparatorFunction(comparator)
    }

    isValid(): boolean {
        return this._array.filter((elem: T) => {
            return this._comparator(elem, this._element)
        }).length !==0
    }

}