import React from "react";
import {Card, Modal} from "react-bulma-components";
import {getDayColumnDisplay} from "../../../Generic/formatter/dayFormatter";
import useTranslation from "../../../Generic/hooks/useTranslation";

interface DeleteWeekModalProps {
    show: boolean,
    day: Date,
    onDelete: () => void,
    onClose: () => void
}

export function DeleteDayModal (props: DeleteWeekModalProps) {
    const {trans} = useTranslation();

    function getButtonTitle (type) {
        switch (type) {
            case 'onDelete':
                return trans('planning.deleteDay.modal.form.button.delete');
            case 'onCancel':
                return trans('planning.deleteDay.modal.form.button.cancel');
        }
    }
    return (
        <Modal show={props.show} onClose={props.onClose} closeOnBlur={true} showClose={false}>
            <Modal.Card>
                <Modal.Card.Header onClose={props.onClose}>
                    <Modal.Card.Title>
                        {trans('planning.deleteDay.modal.deleteTitle', {'displayDay': getDayColumnDisplay(props.day)})}
                    </Modal.Card.Title>
                </Modal.Card.Header>
                <Modal.Card.Body id='deleteDayModalBody'>
                    <Card>
                        <Card.Content>
                            {trans('planning.deleteDay.modal.deleteContent', {'displayDay': getDayColumnDisplay(props.day)})}
                        </Card.Content>
                        <Card.Footer>
                            <Card.Footer.Item onClick={props.onDelete}>{getButtonTitle('onDelete')}</Card.Footer.Item>
                            <Card.Footer.Item onClick={props.onClose}>{getButtonTitle('onCancel')}</Card.Footer.Item>
                        </Card.Footer>
                    </Card>
                </Modal.Card.Body>
            </Modal.Card>
        </Modal>
    )
}