import Assignment, { isAssigment } from "../../../model/assignment.interface";
import {ReplacementType} from "./replacementModal";
import {isReplacement} from "../../../model/replacement.interface";
import {useContext, useState} from "react";
import { PlanningContext } from "../../../context/PlanningContextProvider";
import { findOneAssignment } from "../../../repository/State/stateAssignmentRepository";

export function useReplacementAssignment(assignment: Assignment, replacementType: ReplacementType) {
    const {state} = useContext(PlanningContext)

    /**
     * 1. replacementType = '' => assignment
     * 2. replacementType = 'replaced => assignment.replacedReplacement.assignmentReplacing
     * 3. replacementType = 'replacing' => assignment.replacingReplacement.assignmentReplaced
     * */

    let internalReplacementAssignment = null

    if (replacementType === 'replaced') {
        internalReplacementAssignment = isReplacement(assignment.replacedReplacement) ? findOneAssignment(state, assignment.replacedReplacement.assignmentReplacing) : null
    } else if (replacementType === 'replacing') {
        internalReplacementAssignment = isReplacement(assignment.replacingReplacement) ? findOneAssignment(state, assignment.replacingReplacement.assignmentReplaced) : null
    }

    const [replacementAssignment, setReplacementAssignment] = useState<Assignment|null>(internalReplacementAssignment)
    const [replacementAssignmentHasChanged, setReplacementAssignmentHasChanged] = useState<boolean>(false)

    const updateValue = (a: Assignment|null) => {
        if (a !== replacementAssignment) {
            setReplacementAssignment(a)
            setReplacementAssignmentHasChanged(true)
        }
    }

    return {replacementAssignment, setReplacementAssignment: updateValue, replacementAssignmentHasChanged}
}