import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import AssignedSchedule from "../../../model/assignedSchedule.interface";
import {AssignedScheduleModal} from "../AssignedScheduleModal/assignedScheduleModal";
import {AssignedScheduleFactory} from "../../../model/Factory/assignedScheduleFactory";
import Line from "../../../model/line.interface";

type NewPlanningCellSchedule = {
    line: Line,
    day: Date
}

export function NewPlanningCellSchedule({line, day}: NewPlanningCellSchedule) {
    const [hovered, setHovered] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [newAssignedSchedule, setNewAssignedSchedule] = useState<AssignedSchedule>(AssignedScheduleFactory.create(line, day))
    function onMouseEnter(){
       setHovered(true)
    }

    function onMouseLeave(){
        setHovered(false)
    }

    function openModal() {
        setShowModal(true)
    }

    function closeModal() {
        setShowModal(false)
    }

    return (
        <div className="newScheduleContent" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {hovered?(
                <FontAwesomeIcon icon={faPlusCircle} onClick={openModal}/>
            ):""}
            {showModal ? (
                <AssignedScheduleModal show={showModal} closeModal={closeModal} newAssignedSchedule={true} assignedSchedule={newAssignedSchedule} />
            ):''}
        </div>
    )
}