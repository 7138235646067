import Assignment from "../../../model/assignment.interface";
import {useState} from "react";
import {ReplacementType} from "./replacementModal";
import {isReplacement} from "../../../model/replacement.interface";

export function useComment(assignment: Assignment, replacementType: ReplacementType) {
    /**
     * 1. If no replacement at all, comment  = ''
     * 2. if replaced, comment value : assignment.replacedReplacement.comment
     * 3. if replacing, comment value : assignment.replacingReplacement.comment
     */

    let comment: string = ''

    if (replacementType ==='replaced') {
        comment = isReplacement(assignment.replacedReplacement) ? assignment.replacedReplacement.comment : ''
    } else if (replacementType === 'replacing') {
        comment = isReplacement(assignment.replacingReplacement) ? assignment.replacingReplacement.comment : ''
    }

    const [commentValue, setCommentValue] = useState<string>(comment)
    const [commentValueHasChanged, setCommentValueHasChanged] = useState<boolean>(false)

    const updateValue = (a: string) => {
        if (a !== commentValue) {
            setCommentValue(a)
            setCommentValueHasChanged(true)
        }
    }

    return {commentValue, setCommentValue: updateValue, commentValueHasChanged}
}