import {faExclamationTriangle, faMinusCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import {Tag} from "react-bulma-components";
import useTranslation from "../../../Generic/hooks/useTranslation";
import { PlanningContext } from "../../context/PlanningContextProvider";

export const PlanningTableLegend = () => {
    const {state} = useContext(PlanningContext)
    const {trans} = useTranslation()
    return (
        <div id="planningTableLegend">
          <div>
            <div className="title my-3">{trans('planning.legend.title')} - {trans('planning.legend.subtitle.assignment')}</div>
            <ul className="legend-list mt-1">
              <li><Tag className="scheduleTrigram has-margin-left-5 inTrainingTechnician">AAA</Tag> : {trans('planning.legend.assignment.intraining')}</li>
              <li><Tag className="scheduleTrigram has-margin-left-5 trainedTechnician">AAA</Tag> : {trans('planning.legend.assignment.standard')}</li>
              <li><Tag className="scheduleTrigram has-margin-left-5 inQualificationTechnician">AAA</Tag> : {trans('planning.legend.assignment.inqualification')}</li>
              <li><Tag className="scheduleTrigram has-margin-left-5 qualifiedTechnician">AAA</Tag> : {trans('planning.legend.assignment.qualified')}</li>
              <li><Tag className="scheduleTrigram has-margin-left-5 is-updated">AAA</Tag> : {trans('planning.legend.assignment.changed')}</li>
              <li><Tag className="scheduleTrigram has-margin-left-5 has-background-grey-lighter">AAA</Tag> : {trans('planning.legend.assignment.replacedorreplacing')}</li>
              <li><Tag className="scheduleTrigram has-margin-left-5 notSamePlanningTechnician"><FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" /> AAA</Tag> : {trans('planning.legend.assignment.notsameplanning')}</li>
              <li><Tag className="scheduleTrigram has-margin-left-5 inactiveTechnician">AAA</Tag> : {trans('planning.legend.assignment.inactive')}</li>
            </ul>
          </div>

          {state.isLogged && 
          <div>
            <div className="title my-3">{trans('planning.legend.title')} - {trans('planning.legend.subtitle.schedule')}</div>
            <ul className="legend-list mt-1">
              <li><FontAwesomeIcon className="has-margin-left-5" icon={faMinusCircle} /> : {trans('planning.legend.schedule.incomplete')}</li>
              <li><FontAwesomeIcon className="has-margin-left-5" icon={faPlusCircle} /> : {trans('planning.legend.schedule.notfull')}</li>
              <li><FontAwesomeIcon className="has-margin-left-5 has-text-danger" icon={faExclamationTriangle} /> : {trans('planning.legend.schedule.morethancomplete')}</li>
              <li><FontAwesomeIcon className="has-text-warning has-margin-left-5" icon={faExclamationTriangle} /> : {trans('planning.legend.schedule.noqualifiedtechnician')}</li>
            </ul>
          </div>
          }
        </div>
    )
}
