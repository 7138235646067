import '../../../css/switch.scss'
import {useRef} from "react";

export type LeftOrRight = 'left' | 'right'

interface SwitchProps {
    leftOrRight: LeftOrRight,
    handleNewSide: (lr: LeftOrRight) => void
}

export const Switch = ({leftOrRight, handleNewSide}: SwitchProps) => {
    const ref = useRef<HTMLInputElement>(null)

    const handleSwitch = () => {
        if (leftOrRight === 'left') {
            handleNewSide('right')
        } else {
            handleNewSide('left')
        }
    }

    return (
        <>
            <input
                className="react-switch-checkbox"
                id={`react-switch-new`}
                type="checkbox"
                checked={leftOrRight === 'right'}
                onChange={handleSwitch}
            />
            <label
                className="react-switch-label"
                htmlFor={`react-switch-new`}
            >
                <span className={`react-switch-button`} />
            </label>
        </>
    );
};