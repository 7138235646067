import {Constraint} from "../../../Generic/validation/constraint.interface";
import Technician from "../../model/technician.interface";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import {PlanningContainer} from "../../context/container";
import {TechnicianService} from "../../model/Service/technicianService";
import {filter, isNumber} from "lodash";
import Assignment from "../../model/assignment.interface";

export class TechnicianIsAssignedToAssignedScheduleConstraint implements Constraint {
    _technician: Technician
    _assignedSchedule: AssignedSchedule
    errorMessage: "The technician is not qualified for the line of the Assigned Schedule"

    constructor(technician: Technician|number, assignedSchedule: AssignedSchedule) {
        if (isNumber(technician)) {
            technician = PlanningContainer.technicianRepository.findOne(technician)
        }

        this._technician = technician
        this._assignedSchedule = assignedSchedule
    }

    isValid(): boolean {
        return this._assignedSchedule.assignments.filter((a: Assignment) => a.technicianId === this._technician.id).length > 0;
    }
}
