import {useContext, useMemo, useState} from "react";
import AssignedSchedule, {isSameAssignedSchedule} from "../../../model/assignedSchedule.interface";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleDoubleRight,
    faExclamationTriangle,
    faMinusCircle,
    faPlusCircle
} from "@fortawesome/free-solid-svg-icons";
import {PlanningContainer} from "../../../context/container";
import {useDrop} from "react-dnd";
import {PlanningContext} from "../../../context/PlanningContextProvider";
import Assignment from "../../../model/assignment.interface";
import {TechnicianService} from "../../../model/Service/technicianService";
import {assignedScheduleRepository} from "../../../repository/assignedScheduleRepository";
import {PlanningCellAssignment} from "./planningCellAssignment";
import {sortByFunction} from "../../../../Generic/libraries/sort";
import {AssignedScheduleModal} from "../AssignedScheduleModal/assignedScheduleModal";
import {DateUtils} from "../../../../Generic/libraries/dateManipulation";
import {BooleanConstraint} from "../../../../Generic/validation/constraint/BooleanConstraint";
import {
    SamePlanningModeForTechnicianAndScheduleConstraint
} from "../../../validation/constraints/SamePlanningModeForTechnicianAndScheduleConstraint";
import useValidator from "../../../../Generic/hooks/useValidator";

type PlanningCellAssignedScheduleProps = {
    assignedSchedule: AssignedSchedule
}

export function PlanningCellAssignedSchedule(props: PlanningCellAssignedScheduleProps) {
    const {state, actions} = useContext(PlanningContext)
    const {validate} = useValidator()

    const currentAssignedSchedule = useMemo<AssignedSchedule>(() => {
        return {...props.assignedSchedule}
    }, [props.assignedSchedule])
    const [showModal, setShowModal] = useState<boolean>(false)
    const currentSchedule = useMemo(() => PlanningContainer.scheduleRepository.findOne(currentAssignedSchedule.scheduleId), [currentAssignedSchedule])
    const currentLine = PlanningContainer.lineRepository.findOne(currentAssignedSchedule.lineId)
    const currentAssignments = sortByFunction(currentAssignedSchedule.assignments, (a: Assignment) => PlanningContainer.technicianRepository.findOne(a.technicianId)?.trigram)

    const usedCapacity = useMemo<number>(() => {
        return assignedScheduleRepository.getUsedCapacity(currentAssignedSchedule);
    }, [currentAssignedSchedule])

    /*************
     * Actions
     */
    const clickOnTitle = () => {
        if (state.isLogged){
            setShowModal(true)
        }
    }

    const dropAssignment = (assignment: Assignment, fromAssignedSchedule: AssignedSchedule) => {
        actions.moveAssignmentToAssignedSchedule(assignment, fromAssignedSchedule, currentAssignedSchedule)
    }

    const closeModal = () => {
        setShowModal(false)
    }

    /***************
     * Drag & Drop
     */
    const [{ didDrop, draggedItem, canDrop },drop] = useDrop<{assignment: Assignment, assignedSchedule: AssignedSchedule}, any, any>({
        accept: 'assignment',
        canDrop: (item, _monitor) => {
            /**
             * can drop if
             * 1. user is logged
             * 2. both target assigned schedule and dropped assignment assigned schedules are different
             * 3. both target and dropped dates are the same
             * 4. dropped technician is either trained or in training for the target line
             * 5. dropped technician has the same planning mode as the target assignedschedule schedule
             */
            return validate([
                new BooleanConstraint(state.isLogged),
                new BooleanConstraint(!isSameAssignedSchedule(item.assignedSchedule, currentAssignedSchedule)),
                new BooleanConstraint(DateUtils.dateEquals(currentAssignedSchedule.day, item.assignedSchedule.day)),
                new BooleanConstraint(TechnicianService.isTechnicianTrainedForLine(item.assignment.technicianId, currentLine) || TechnicianService.isTechnicianInTrainingForLine(item.assignment.technicianId, currentLine)),
                new SamePlanningModeForTechnicianAndScheduleConstraint(currentSchedule, item.assignment.technicianId)
            ])
        },
        drop: (item, _monitor) => {
            dropAssignment(item.assignment, item.assignedSchedule)
        },
        collect: monitor => ({
            canDrop: monitor.canDrop(),
            draggedItem: monitor.getItem(),
            didDrop: monitor.didDrop(),
        }),
    })

    const hasDropAvailable = () => {
        if (draggedItem === null || didDrop) {
            return undefined;
        }
        if (canDrop) {
            return true;
        } else if (state.isLogged){
            return false;
        }
    }

    /*******************
     * Helper functions
     */
    const isIncomplete = () => {
        if (!state.isLogged || currentAssignedSchedule.assignments.length === 0) {
            return false;
        }

        return usedCapacity < assignedScheduleRepository.getMinCapacity(currentAssignedSchedule);
    }

    const isNotFull = () => {
        if (!state.isLogged || currentAssignedSchedule.assignments.length === 0 || isIncomplete()) {
            return false;
        }

        return usedCapacity < assignedScheduleRepository.getPreferredCapacity(currentAssignedSchedule);
    }

    const isTooMuch = () => {
        if (!state.isLogged) {
            return false;
        }

        return usedCapacity > assignedScheduleRepository.getMaxCapacity(currentAssignedSchedule);
    }

    return (
        <div id={"schedule-"+currentSchedule.dayPosition} key={"schedule-"+currentSchedule.dayPosition} className="scheduleContent">
            <div className="cellScheduleTitle" onClick={clickOnTitle} ref={drop}>
                {hasDropAvailable() === true && <FontAwesomeIcon className="has-margin-right-5" icon={faAngleDoubleRight} />}
                {currentSchedule.name}
                {isIncomplete() && <FontAwesomeIcon className="has-margin-left-5" icon={faMinusCircle} />}
                {isNotFull() && <FontAwesomeIcon className="has-margin-left-5" icon={faPlusCircle} />}
                {isTooMuch() && <FontAwesomeIcon className="has-margin-left-5 has-text-danger" icon={faExclamationTriangle} />}
                {!assignedScheduleRepository.hasAtLeastOneQualifiedTechnicians(currentAssignedSchedule) && <FontAwesomeIcon className="has-text-warning has-margin-left-5" icon={faExclamationTriangle} />}
            </div>
            <div className="cellScheduleContent">
                {currentAssignments.map((assignment: Assignment) => (
                    <PlanningCellAssignment key={assignment.id ?? assignment.frontId} assignment={assignment} assignedSchedule={currentAssignedSchedule}/>
                ))}
            </div>

            {showModal ? (
                <AssignedScheduleModal show={showModal} closeModal={closeModal} newAssignedSchedule={false} assignedSchedule={currentAssignedSchedule} />
            ):''}
        </div>
    )
}
