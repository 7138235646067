import useTranslation from "../../../../Generic/hooks/useTranslation";
import {useCallback, useContext, useState} from "react";
import {PlanningContext} from "../../../context/PlanningContextProvider";
import AssignedSchedule from "../../../model/assignedSchedule.interface";
import {DateUtils} from "../../../../Generic/libraries/dateManipulation";
import Assignment from "../../../model/assignment.interface";
import {Tag} from "react-bulma-components";
import {PlanningContainer} from "../../../context/container";
import {DuplicateAssignedScheduleData} from "../../../context/Actions/duplicateAssignedSchedule";
import Technician from "../../../model/technician.interface";
import {classNames} from "../../../../Generic/libraries/style";
import useValidator from "../../../../Generic/hooks/useValidator";
import {InArrayConstraint} from "../../../../Generic/validation/constraint/inArrayConstraint";
import {removeElementBy, removeElementByCallback, removeElementByComparator} from "../../../../Generic/libraries/array";

interface AssignedScheduleModalDuplicateFormProps {
    assignedSchedule: AssignedSchedule,
    duplicateData: DuplicateAssignedScheduleData,
    setDuplicateData: (data: DuplicateAssignedScheduleData) => void
}

export function AssignedScheduleModalDuplicateForm({assignedSchedule, duplicateData, setDuplicateData}: AssignedScheduleModalDuplicateFormProps) {
    const {state} = useContext(PlanningContext);
    const {trans} = useTranslation();
    const {validate} = useValidator()

    const isDaysToDuplicate = useCallback((d: Date) => {
        return validate([new InArrayConstraint(duplicateData.days, d, DateUtils.dateEquals)])
    }, [duplicateData])

    const isTechnicianToDuplicate = useCallback((t: Technician) => {
        return validate([new InArrayConstraint(duplicateData.technicians, t, 'id')])
    }, [duplicateData])

    const selectDay = (d: Date) => {
        if (isDaysToDuplicate(d)) {
            setDuplicateData({...duplicateData, days: removeElementByComparator(duplicateData.days, d, DateUtils.dateEquals)})
        } else {
            setDuplicateData({...duplicateData, days: [...duplicateData.days, d]})
        }
    }

    const selectTechnician = (t: Technician) => {
        if (isTechnicianToDuplicate(t)) {
            setDuplicateData({...duplicateData, technicians: removeElementBy(duplicateData.technicians, t,'id')})
        } else {
            setDuplicateData({...duplicateData, technicians: [...duplicateData.technicians, t]})
        }
    }

    return (
        <div className="duplicateScheduleForm">
            {trans('planning.assignedSchedule.modal.duplicate.text')}
            <div className="columns mt-3">
                <div className="column is-3 is-bold">
                    { trans('planning.assignedSchedule.modal.duplicate.days.label') }
                </div>
                <div className="column">
                    <Tag.Group>
                        { state.week.days.map((d: Date) => {
                            if (DateUtils.dateEquals(d, assignedSchedule.day)) {
                                return null
                            }
                            return <Tag
                                key={d.getDay()}
                                className={classNames("is-clickable",
                                    isDaysToDuplicate(d) ? 'has-background-success' : "")}
                                onClick={() => selectDay(d)}
                            >
                                { DateUtils.getDateDisplay(d, 'D').toUpperCase() }
                            </Tag>
                        }) }
                    </Tag.Group>

                </div>
            </div>

            <div className="columns">
                <div className="column is-3 is-bold">
                    { trans('planning.assignedSchedule.modal.duplicate.technicians.label') }
                </div>
                <div className="column">
                    <Tag.Group>
                        {assignedSchedule.assignments.map((a: Assignment) => {
                            const technician = PlanningContainer.technicianRepository.findOne(a.technicianId)
                            return <Tag
                                key={technician.id}
                                className={classNames("is-clickable",
                                    isTechnicianToDuplicate(technician) ? 'has-background-success' : '')}
                                onClick={() => selectTechnician(technician)}
                            >
                                { technician.trigram }
                            </Tag>
                        })}
                    </Tag.Group>
                </div>
            </div>
        </div>
    )
}