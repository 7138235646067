import  {useMemo} from "react";
import AssignedSchedule from "../../../model/assignedSchedule.interface";
import {Form, Tag} from "react-bulma-components";
import {PlanningContainer} from "../../../context/container";
import Technician from "../../../model/technician.interface";
import {
    SamePlanningModeForTechnicianAndScheduleConstraint
} from "../../../validation/constraints/SamePlanningModeForTechnicianAndScheduleConstraint";
import {assignedScheduleRepository} from "../../../repository/assignedScheduleRepository";
import useTranslation from "../../../../Generic/hooks/useTranslation";
import useValidator from "../../../../Generic/hooks/useValidator";
import TechnicianTag from "../technicianTag";

type AssignedScheduleTechnicianTrigramsProps = {
    assignedSchedule: AssignedSchedule,
    onSelectTechnician: (t: Technician) => void,
    usedCapacity: number,
    capacityReached:boolean,
    availableTechnicians: Technician[]
}

export function AssignedScheduleTechnicianTrigrams(props: AssignedScheduleTechnicianTrigramsProps) {
    const {trans} = useTranslation()
    const {validate} = useValidator()

    const classNameMinCapReached = useMemo(() =>{
        if (props.assignedSchedule.assignments.length === 0){
            return "has-text-danger";
        } else if (props.usedCapacity < assignedScheduleRepository.getPreferredCapacity(props.assignedSchedule)){
            return "has-text-warning";
        } else {
            return "has-text-success";
        }
    }, [props.usedCapacity])

    const toPickTechnicians = useMemo<Technician[]>(() => {
        return props.availableTechnicians.filter((t: Technician) => {
            //keep the technicians that have the same planning mode as the assigned Schedule
            return validate([new SamePlanningModeForTechnicianAndScheduleConstraint(props.assignedSchedule.scheduleId, t)])
        })
    }, [props.assignedSchedule])

    return (
        <div className="columns">
            <Form.Label className="column is-one-quarter">
                <p>{trans('planning.assignedSchedule.modal.form.technicians.label')}</p>
                <p className={'technicianCapacity ' + classNameMinCapReached }>{trans('planning.assignedSchedule.modal.form.technicians.minValue', {'value': PlanningContainer.lineRepository.findOne(props.assignedSchedule.lineId)?.capacity.toString()})}</p>
                <p className={'technicianCapacity ' + classNameMinCapReached }>{trans('planning.assignedSchedule.modal.form.technicians.preferredValue', {'value': props.assignedSchedule.preferenceCapacity.toString()})}</p>
                <p className='technicianCapacity'>{trans('planning.assignedSchedule.modal.form.technicians.maxValue', {'value': assignedScheduleRepository.getMaxCapacity(props.assignedSchedule).toString()})}</p>
                <p>Used Capacity: {props.usedCapacity.toString()}</p>
            </Form.Label>
            <Form.Control className="column assignedScheduleFormTechnicians">
                { /*List of available technicians for AssignedSchedule*/ }
                <Tag.Group>
                    {toPickTechnicians.map(technician => {
                        let isAssigned = props.assignedSchedule.assignments.filter(tempAssignment => {return tempAssignment.technicianId === technician.id}).length > 0;
                        return (
                            <TechnicianTag
                              key={technician.id}
                              technician={technician}
                              isAssigned={isAssigned}
                              additionalClass={'is-italic'}
                              onSelectTechnician={props.onSelectTechnician}
                              line={PlanningContainer.lineRepository.findOne(props.assignedSchedule.lineId)}
                            />
                        )
                    }, this)}

                </Tag.Group>
                {props.capacityReached && (
                    <div>{trans('planning.assignedSchedule.modal.form.technicians.capacityReached')}</div>
                )}
                {!assignedScheduleRepository.hasAtLeastOneQualifiedTechnicians(props.assignedSchedule) && (
                    <div>{trans('planning.assignedSchedule.modal.form.technicians.noQualifiedTechnicians')}</div>
                )}
            </Form.Control>
        </div>

    );
}
