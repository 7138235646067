import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Card, Level, Modal, Form} from "react-bulma-components";
import useTranslation from "../../../../Generic/hooks/useTranslation";
import AssignedSchedule from "../../../model/assignedSchedule.interface";
import {PlanningContext} from "../../../context/PlanningContextProvider";
import Assignment from "../../../model/assignment.interface";
import {getIntersectionOfArrays} from "../../../../Generic/libraries/array";
import {AssignedScheduleModalEditForm} from "./AssignedScheduleModalEditForm";
import {PlanningContainer} from "../../../context/container";
import {AssignedScheduleModalDuplicateForm} from "./AssignedScheduleModalDuplicateForm";
import {DuplicateAssignedScheduleData} from "../../../context/Actions/duplicateAssignedSchedule";
import useValidator from "../../../../Generic/hooks/useValidator";
import {NotSameConstraint} from "../../../../Generic/validation/constraint/notSameConstraint";
import {DateUtils} from "../../../../Generic/libraries/dateManipulation";
import {BooleanConstraint} from "../../../../Generic/validation/constraint/BooleanConstraint";
import Technician from "../../../model/technician.interface";
import {AssignmentFactory} from "../../../model/Factory/assignmentFactory";

type AssignedScheduleModalProps = {
    show: boolean,
    closeModal: () => void,
    newAssignedSchedule: boolean,
    assignedSchedule: AssignedSchedule,
}

type ModalStatus = 'edit' | 'duplicate' | 'delete'

type ModalButtonActions = 'onSave' | 'onDelete' | 'onCancel' | 'onDuplicate'

export function AssignedScheduleModal(props: AssignedScheduleModalProps) {
    const {state, actions} = useContext(PlanningContext);
    const {trans} = useTranslation();
    const {validate} = useValidator();

    const [modalStatus, setModalStatus] = useState<ModalStatus>('edit')
    const [currentAssignedSchedule, setCurrentAssignedSchedule] = useState<AssignedSchedule|null>({...props.assignedSchedule})
    const [duplicateData, setDuplicateData] = useState<DuplicateAssignedScheduleData>(
        {
            days: state.week.days.filter((d: Date) => {
                return validate([
                    new NotSameConstraint(d, props.assignedSchedule.day, DateUtils.dateEquals),
                    new BooleanConstraint(d.getDay() !== 0 && d.getDay() !== 6)
                ])
            }),
            technicians: props.assignedSchedule.assignments.map((a: Assignment) => PlanningContainer.technicianRepository.findOne(a.technicianId))
        }
    )

    const isSavable = useMemo<boolean>(() => {
        const isScheduleChanged:boolean = currentAssignedSchedule.scheduleId !== props.assignedSchedule.scheduleId

        let areTechniciansUpdated: boolean = false
        if (currentAssignedSchedule.assignments.length !== props.assignedSchedule.assignments.length) {
            areTechniciansUpdated = true
        }

        const intersection = getIntersectionOfArrays(props.assignedSchedule.assignments, currentAssignedSchedule.assignments, (a: Assignment) => a.technicianId)
        if (intersection.length !== props.assignedSchedule.assignments.length) {
            areTechniciansUpdated = true
        }

        return !isNaN(currentAssignedSchedule.scheduleId) && currentAssignedSchedule.scheduleId !== null && (isScheduleChanged || areTechniciansUpdated);
    }, [currentAssignedSchedule])

    const isNewSchedule = useMemo(() => props.newAssignedSchedule, [])

    /**********************
     * Actions
     */
    function requestSave() {
        if (!isSavable) {
            return
        }
        if (props.newAssignedSchedule) {
            actions.createNewAssignedSchedule(currentAssignedSchedule)
        } else {
            actions.updateAssignedSchedule(currentAssignedSchedule, props.assignedSchedule)
        }
    }

    function requestDeletion() {
        if (modalStatus !== 'delete') {
            setModalStatus('delete')
        } else {
            actions.deleteAssignedSchedule(currentAssignedSchedule)
        }
    }

    function requestDuplication() {
        if (modalStatus !== 'duplicate') {
            setModalStatus('duplicate')
        } else {
            actions.duplicateAssignedSchedule(props.assignedSchedule, duplicateData)
        }
    }

    function cancelAction() {
        if (modalStatus === 'edit') {
            props.closeModal()
        } else if (modalStatus === 'delete') {
            setModalStatus('edit')
        } else if (modalStatus === 'duplicate') {
            setModalStatus('edit')
        }
    }

    /******************
     * Helpers
     */

    function getFormTitle() {
        if (props.newAssignedSchedule)
            return trans('planning.assignedSchedule.modal.title.new');

        else return trans('planning.assignedSchedule.modal.title.update');
    }

    const getDisabledClassName = useCallback((buttonTitle: ModalButtonActions) => {
        let disabledClassName = 'buttonDisabled';
        switch (buttonTitle) {
            case 'onSave':
                if (!isSavable){
                    return disabledClassName;
                }
                break;
            case 'onDelete':
                if (isNewSchedule){
                    return disabledClassName
                }
                break;
            case 'onDuplicate':
                if (isSavable) {
                    return disabledClassName
                }
                if (isNewSchedule){
                    return disabledClassName
                }
                break;
            case 'onCancel':
                break;
        }

        return 'buttonEnabled';
    }, [isSavable, isNewSchedule])

    const assignedScheduleModalContent = useMemo<JSX.Element>(() => {
        if (modalStatus === 'edit') {
            return <AssignedScheduleModalEditForm assignedSchedule={currentAssignedSchedule} setAssignedSchedule={setCurrentAssignedSchedule} />
        }
        if (modalStatus === 'duplicate') {
            return <AssignedScheduleModalDuplicateForm assignedSchedule={props.assignedSchedule} duplicateData={duplicateData} setDuplicateData={setDuplicateData}/>
        }
        if (modalStatus === 'delete') {
            return (
                <Level>
                    <Level.Item>
                        {trans('planning.assignedSchedule.modal.form.delete.confirmation', {'scheduleName': PlanningContainer.scheduleRepository.findOne(currentAssignedSchedule?.scheduleId).name})}
                    </Level.Item>
                </Level>
            )
        }
    }, [props.assignedSchedule, currentAssignedSchedule, modalStatus, duplicateData])

    const assignedScheduleModalActionButtons = useMemo<JSX.Element[]>(() => {
        const buttons: {[k in ModalButtonActions]: JSX.Element} = {
            onSave: <Card.Footer.Item key="onSave" className={getDisabledClassName('onSave')} onClick={requestSave}>{props.newAssignedSchedule ? trans('planning.assignedSchedule.modal.button.update') : trans('planning.assignedSchedule.modal.button.update')}</Card.Footer.Item>,
            onDelete: <Card.Footer.Item key="onDelete" className={getDisabledClassName('onDelete')} onClick={requestDeletion}>{trans('planning.assignedSchedule.modal.button.delete')}</Card.Footer.Item>,
            onCancel: <Card.Footer.Item key="onCancel" className={getDisabledClassName('onCancel')} onClick={cancelAction}>{trans('planning.assignedSchedule.modal.button.cancel')}</Card.Footer.Item>,
            onDuplicate: <Card.Footer.Item key="onDuplicate" className={getDisabledClassName('onDuplicate')} onClick={requestDuplication}>{trans('planning.assignedSchedule.modal.button.duplicate')}</Card.Footer.Item>
        }
        if (modalStatus === 'edit') {
            return [buttons.onSave, buttons.onDelete, buttons.onDuplicate, buttons.onCancel]
        } if (modalStatus === 'duplicate') {
            return [buttons.onDuplicate, buttons.onCancel]
        } if (modalStatus === 'delete') {
            return [buttons.onDelete, buttons.onCancel]
        }
    }, [modalStatus, getDisabledClassName, duplicateData, currentAssignedSchedule])

    return (
        <Modal show={props.show} onClose={props.closeModal} closeOnBlur={true} showClose={false}>
            <Modal.Card>
                <Modal.Card.Header onClose={props.closeModal}>
                    <Modal.Card.Title>{getFormTitle()}</Modal.Card.Title>
                </Modal.Card.Header>
                <Modal.Card.Body id='assignedScheduleModalBody'>
                    <Card>
                        <Card.Content>
                            {assignedScheduleModalContent}
                        </Card.Content>
                        {<Card.Footer>
                            { assignedScheduleModalActionButtons.map(button => button) }
                        </Card.Footer>}
                    </Card>
                </Modal.Card.Body>
            </Modal.Card>
        </Modal>
    )
}
