import React, {ChangeEvent, useContext, useMemo} from "react";
import Assignment from "../../../model/assignment.interface";
import {PlanningContext} from "../../../context/PlanningContextProvider";
import useTranslation from "../../../../Generic/hooks/useTranslation";
import {Form} from "react-bulma-components";
import {PlanningContainer} from "../../../context/container";
import {getAvailableAssignmentsForReplacement} from "../../../repository/State/stateAssignmentRepository";
import { ReplacementType } from "./replacementModal";

interface TechnicianFormProps {
    assignment: Assignment,
    replacementType: ReplacementType,
    assignmentReplacement: Assignment | null,
    changeValue: (a: Assignment) => void
}

export function TechnicianForm(props: TechnicianFormProps) {
    const {state} = useContext(PlanningContext)
    const {trans} = useTranslation();   
    const assignmentTechnician = PlanningContainer.technicianRepository.findOne(props.assignment.technicianId) 
    

    if (state.isLogged && props.replacementType !== 'replacing'){
        const availableAssignments = useMemo<Assignment[]>(() => getAvailableAssignmentsForReplacement(state, props.assignment), [props.assignment])

        const selectValue = useMemo(() => {
            if (props.assignmentReplacement === null) {
                return ''
            }
            return props.assignmentReplacement.id !== null ? props.assignmentReplacement.id.toString() : props.assignmentReplacement.frontId
        }, [props.assignmentReplacement])

    
        const onAssignmentReplacementChange = (e: ChangeEvent<HTMLSelectElement>) => {
            const assignmentIdOrFrontId = e.target.value
            
            const targetAssignment = availableAssignments.filter((a: Assignment) => {
                return a.frontId === assignmentIdOrFrontId || a.id?.toString() === assignmentIdOrFrontId
            })

            if (targetAssignment.length > 0) {
                props.changeValue(targetAssignment[0])
            } else if (props.assignment.frontId === assignmentIdOrFrontId || props.assignment.id?.toString() === assignmentIdOrFrontId) {
                props.changeValue(props.assignment)
            } else {
                props.changeValue(null)
            }

        }

        return (
            <Form.Field>
                <Form.Label>{trans('planning.assignment.modal.form.technician.selectTitle', {'trigram': assignmentTechnician.trigram})}</Form.Label>
                <Form.Select name="technicianValue" onChange={onAssignmentReplacementChange} value={selectValue}>
                    <option key={"none"} value={''}>{trans('planning.assignment.modal.form.technician.emptyValue')}</option>
                    <option key={props.assignment.id} value={props.assignment.id !== null ? props.assignment.id : props.assignment.frontId}>{trans('planning.assignment.modal.form.technician.none')}</option>
                    {
                        availableAssignments.map((assignment) => {
                            const technician = PlanningContainer.technicianRepository.findOne(assignment.technicianId)
                            const assignmentId = assignment.id !== null ? assignment.id.toString() : assignment.frontId;
                            return (<option key={assignmentId} value={assignmentId}>{technician.trigram}</option>);
                        })
                    }
                </Form.Select>
            </Form.Field>
        );
    } else {
        if (props.replacementType === 'replacing'){
            const replacedTechnician = PlanningContainer.technicianRepository.findOne(props.assignmentReplacement.technicianId)
            return (
                <Form.Field>
                    <Form.Label>{trans('planning.assignment.modal.form.technician.replacementTitle')}</Form.Label>
                    <div>{trans('planning.assignment.modal.form.technician.replacementOf', {'replacedTrigram': replacedTechnician.trigram })}</div>
                </Form.Field>
            );
        }
        
        if (props.replacementType === ''){
            return (
                <Form.Field>
                    <Form.Label>{trans('planning.assignment.modal.form.technician.replacementTitle')}</Form.Label>
                    <div>{trans('planning.assignment.modal.form.technician.noReplacement')}</div>
                </Form.Field>
            )
        } else {
            const replacedTechnician = PlanningContainer.technicianRepository.findOne(props.assignment.technicianId)
            const replacingTechnician = PlanningContainer.technicianRepository.findOne(props.assignmentReplacement.technicianId)
            return (
                
                <Form.Field>
                    <Form.Label>{trans('planning.assignment.modal.form.technician.replacementTitle')}</Form.Label>
                    <div>{trans('planning.assignment.modal.form.technician.replacedBy', {'replacedTrigram': replacedTechnician.trigram, 'replacingTrigram': replacingTechnician.trigram })}</div>
                </Form.Field>
            )
        }
    }
}