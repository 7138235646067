import {Button, Panel} from "react-bulma-components";
import {LeftOrRight, Switch} from "../../../../Generic/components/switch";
import React, {useContext, useMemo, useState} from "react";
import useTranslation from "../../../../Generic/hooks/useTranslation";
import {OperatorPlanningViewType} from "../planningOperatorView";
import {PlanningContainer} from "../../../context/container";
import {PlanningContext} from "../../../context/PlanningContextProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronRight, faDownload} from "@fortawesome/free-solid-svg-icons";

interface ActionPanelProps {
    handleChangeView: (view: OperatorPlanningViewType) => void,
    view: OperatorPlanningViewType
}

export function ActionPanel({handleChangeView, view}: ActionPanelProps) {
    const {state} = useContext(PlanningContext)
    const {trans} = useTranslation();
    const [contentVisible, setContentVisible] = useState<boolean>(false)

    const lr = useMemo<LeftOrRight>(() => {
        if (view === OperatorPlanningViewType.FULL) {
            return 'left'
        } else {
            return 'right'
        }
    }, [view])

    const handleSwitch = (lr: LeftOrRight) => {
        if (lr == 'left') {
            handleChangeView(OperatorPlanningViewType.FULL)
        } else {
            handleChangeView(OperatorPlanningViewType.INTERIM)
        }
    }

    const onExportRequest = () => {
        PlanningContainer.backendRepository.getInterimOperatorPlanningPDF(state.week);
    }

    return (
        <Panel id="action-panel">
            <Panel.Header
                key="panel-heading"
                className={'panel-header is-flex is-justify-content-space-between is-align-items-center is-clickable' + (contentVisible ? ' visible' : '')}
                onClick={() => setContentVisible(!contentVisible)}
            >
                <span>{trans('operatorPlanning.sidePanel.actions.title')}</span>
                <FontAwesomeIcon
                    icon={contentVisible ? faChevronDown : faChevronRight}
                    onClick={() => setContentVisible(!contentVisible)}
                    className="is-clickable"
                />
            </Panel.Header>
            <div className={'panel-content' + (contentVisible ? ' visible' : '')}>
                <Panel.Block className="is-size-5 has-text-weight-medium">
                    {trans('operatorPlanning.sidePanel.actions.viewSelection.title')}
                </Panel.Block>
                <Panel.Block>
                    <div className="sidePanel-action-switch is-flex is-justify-content-space-between is-align-items-center is-full">
                        <div className={"left-side" + (lr === 'left' ? ' has-text-weight-bold' : '')}>
                            {trans('operatorPlanning.sidePanel.actions.viewSelection.all')}
                        </div>
                        <Switch leftOrRight={lr} handleNewSide={handleSwitch}/>
                        <div className={"right-side" + (lr === 'right' ? ' has-text-weight-bold' : '')}>
                            {trans('operatorPlanning.sidePanel.actions.viewSelection.interim')}
                        </div>
                    </div>
                </Panel.Block>
                {view === OperatorPlanningViewType.INTERIM ? (
                    <Panel.Block className="is-flex is-justify-content-space-between is-align-items-center">
                        <div className="mr-5">{trans('operatorPlanning.sidePanel.actions.viewSelection.interimExport')}</div>
                        <Button onClick={onExportRequest}>
                            <FontAwesomeIcon icon={faDownload} className="mr-3"/>
                            {trans('operatorPlanning.sidePanel.actions.viewSelection.interimExport.button')}
                        </Button>
                    </Panel.Block>
                ): null}
            </div>
        </Panel>
    )
}