import React, {useMemo} from "react";
import {Form, Tag} from "react-bulma-components";
import useTranslation from "../../../../Generic/hooks/useTranslation";
import Technician from "../../../model/technician.interface";
import { sortByFunction} from "../../../../Generic/libraries/sort";
import Qualification from "../../../model/qualification.interface";

interface TechnicianQualificationProps {
    technician: Technician
}

export function TechnicianQualification(props: TechnicianQualificationProps) {
    const {trans} = useTranslation()

    const qualifications = useMemo(() => sortByFunction(props.technician.qualifications, (q: Qualification) => q.competency.name), [props.technician])

    return (
        <div className="qualifications">
            <Form.Label>{trans('planning.assignment.modal.form.qualifications.displayTitle')}</Form.Label>
            <div className="qualifications__list">
                {qualifications.map((qualification) => {
                    return <Tag key={qualification.id} className='has-margin-right-5'>{qualification.competency.name}</Tag>
                })}
            </div>
        </div>
    )
}