import {Form} from "react-bulma-components";
import {getSchedulesForAssignedSchedule} from "../../../repository/State/stateAssignedSchedulesRepository";
import {ChangeEvent, useContext, useMemo, useState} from "react";
import Assignment from "../../../model/assignment.interface";
import {PlanningContainer} from "../../../context/container";
import {
    SamePlanningModeForTechnicianAndScheduleConstraint
} from "../../../validation/constraints/SamePlanningModeForTechnicianAndScheduleConstraint";
import AssignedSchedule from "../../../model/assignedSchedule.interface";
import {PlanningContext} from "../../../context/PlanningContextProvider";
import useTranslation from "../../../../Generic/hooks/useTranslation";
import Schedule from "../../../model/schedule.interface";
import useValidator from "../../../../Generic/hooks/useValidator";

interface AssignedScheduleScheduleSelectProps {
    assignedSchedule: AssignedSchedule,
    setAssignedSchedule: (a: AssignedSchedule) => void
}

export function AssignedScheduleScheduleSelect({assignedSchedule, setAssignedSchedule}: AssignedScheduleScheduleSelectProps) {
    const {state} = useContext(PlanningContext);
    const {trans} = useTranslation();
    const {validate} = useValidator()

    const [scheduleValue, setScheduleValue] = useState<number|null>(assignedSchedule.scheduleId)

    function updateScheduleValue(e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) {
        const scheduleId: number = parseInt(e.target.value);
        setScheduleValue(scheduleId)

        if (scheduleId === null) {
            return
        }

        setAssignedSchedule({...assignedSchedule, scheduleId})
    }

    const availableSchedules = useMemo<Schedule[]>(() => {
        return getSchedulesForAssignedSchedule(state, assignedSchedule)
    }, [assignedSchedule])

    return (
        <div className="columns is-vcentered">
            <Form.Label className="column is-one-quarter">
                {trans('planning.assignedSchedule.modal.form.schedule.label')}
            </Form.Label>
            <Form.Control className="column">
                <Form.Select onChange={updateScheduleValue} value={scheduleValue ? scheduleValue.toString() : ""}>
                    <option value="">{trans('planning.assignedSchedule.modal.form.schedule.placeHolder')}</option>
                    {
                        availableSchedules.map(schedule => (
                            <option value={schedule.id} key={schedule.id}>{schedule.name}</option>
                        ))
                    }
                </Form.Select>
            </Form.Control>
        </div>
    )
}