import {DateUtils} from "../../../../Generic/libraries/dateManipulation";
import Assignment, {getAssignmentId} from "../../../model/assignment.interface";
import {DayHeader} from "./dayHeader";
import {ReplacementLine} from "./replacementLine";
import {ReplacementData} from "./operatorPlanningSidePanel";

export function ReplacementBlock({replacementData}: {replacementData: ReplacementData}){
    return (
        <>
            <DayHeader key={DateUtils.getDayIndexFromDay(replacementData.day)} day={replacementData.day}/>
            {replacementData.assignments.map((assignment: Assignment) => {
                return <ReplacementLine key={getAssignmentId(assignment)} assignment={assignment}/>
            })}
        </>
    )
}
