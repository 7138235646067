import {
    getAlreadyAssignedTechniciansNotInAssignedSchedule,
} from "../../../repository/State/stateAssignedSchedulesRepository";
import {assignedScheduleRepository} from "../../../repository/assignedScheduleRepository";
import {AssignedScheduleTechnicianTrigrams} from "./assignedScheduleTechnicianTrigrams";
import {AssignedScheduleAlreadyAssignedTrigram} from "./assignedScheduleAlreadyAssignedTrigram";
import {Dispatch, SetStateAction, useContext, useMemo} from "react";
import {PlanningContext} from "../../../context/PlanningContextProvider";
import AssignedSchedule from "../../../model/assignedSchedule.interface";
import Technician from "../../../model/technician.interface";
import Assignment from "../../../model/assignment.interface";
import {PlanningContainer} from "../../../context/container";
import {TechnicianService} from "../../../model/Service/technicianService";
import {AssignmentFactory} from "../../../model/Factory/assignmentFactory";
import {Constraint} from "../../../../Generic/validation/constraint.interface";
import {BooleanConstraint} from "../../../../Generic/validation/constraint/BooleanConstraint";
import {sortBy} from "../../../../Generic/libraries/sort";
import {AssignedScheduleScheduleSelect} from "./assignedScheduleScheduleSelect";
import {AssignedScheduleNonSamePlanningModeTrigrams} from "./AssignedScheduleNonSamePlanningModeTrigrams";
import useValidator from "../../../../Generic/hooks/useValidator";
import { TechnicianIsAssignedToAssignedScheduleConstraint } from "../../../validation/constraints/TechnicianIsAssignedToAssignedSchedule.constraint";
import { OrConstraint } from "../../../../Generic/validation/constraint/OrConstraint";
import { NotInArrayConstraint } from "../../../../Generic/validation/constraint/notInArrayConstraint";

interface AssignedScheduleModalFormProps {
    assignedSchedule: AssignedSchedule,
    setAssignedSchedule: Dispatch<SetStateAction<AssignedSchedule>>
}

export const AssignedScheduleTechnicianAvailabilityConstraints = (assignedSchedule: AssignedSchedule, technician: Technician): Constraint[] => {
    
    return [
        new OrConstraint([new BooleanConstraint(technician.active), new TechnicianIsAssignedToAssignedScheduleConstraint(technician, assignedSchedule)]),
        new BooleanConstraint(TechnicianService.isTechnicianTrainedOrInTrainingForLine(technician, assignedSchedule.lineId)),
        new BooleanConstraint(!TechnicianService.hasTechnicianADayOff(technician, assignedSchedule.day)),
        
    ]
}

export function AssignedScheduleModalEditForm({assignedSchedule, setAssignedSchedule}: AssignedScheduleModalFormProps) {
    const {state} = useContext(PlanningContext);
    const {validate} = useValidator()

    const usedCapacity = useMemo<number>(() => {
        return assignedScheduleRepository.getUsedCapacity(assignedSchedule);
    }, [assignedSchedule])

    const capacityReached = useMemo<boolean>(() => {
        return usedCapacity === assignedScheduleRepository.getMaxCapacity(assignedSchedule)
    }, [usedCapacity])

    const availableTechnicians = useMemo<Technician[]>(() => {
        const technicianRepository = PlanningContainer.technicianRepository;
        const assignedTechniciansToOtherAssignedSchedules = getAlreadyAssignedTechniciansNotInAssignedSchedule(state, assignedSchedule)

        const assignedScheduleTechnicians: Technician[] = assignedSchedule.assignments
                .map((a: Assignment) => PlanningContainer.technicianRepository.findOne(a.technicianId))
        ;

        /**
         * Keep the following technicians
         * 1. not assigned ones but the ones assigned to the assignedSchedule
         * 2. technician qualified for the Competency needed for the AssignedSchedule line
         * 3. technician is available (no off day) on the day of the assigned schedule
         */
        let availableTechnicians =  technicianRepository.findAll().filter((t: Technician) => {
            const isNotAssigned = assignedTechniciansToOtherAssignedSchedules.filter((aTechnician: Technician) => {
                //keep only the checked technician
                return t.id === aTechnician.id
            }).length === 0;

            return validate([
                new BooleanConstraint(isNotAssigned),
                new NotInArrayConstraint(assignedScheduleTechnicians, t, 'id'),
                ...AssignedScheduleTechnicianAvailabilityConstraints(assignedSchedule, t)
            ])
        })

        availableTechnicians = [...availableTechnicians, ...assignedScheduleTechnicians];

        //sort by trigram ASC
        return sortBy(availableTechnicians, 'trigram')
    }, [assignedSchedule]);

    function onSelectTechnician(t: Technician): void {
        const assignedTechnician = assignedSchedule.assignments.filter((a: Assignment) => a.technicianId === t.id).length !== 0
        const line = PlanningContainer.lineRepository.findOne(assignedSchedule.lineId)

        let newAssignments = [...assignedSchedule.assignments]
        if (assignedTechnician) {
            newAssignments = newAssignments.filter((a: Assignment) => a.technicianId !== t.id)
        } else if (!capacityReached || TechnicianService.isTechnicianInTrainingForLine(t,line)){
            newAssignments.push(AssignmentFactory.create(assignedSchedule.day, t.id, assignedSchedule))
        }

        setAssignedSchedule(prev => {
            return {...prev, assignments: [...newAssignments]}
        })
    }

    return (
        <div className="newScheduleForm">
            <div className="columns">
                <div className="column is-8">
                    <AssignedScheduleScheduleSelect assignedSchedule={assignedSchedule} setAssignedSchedule={setAssignedSchedule} />

                    <AssignedScheduleTechnicianTrigrams
                        assignedSchedule={assignedSchedule}
                        onSelectTechnician={onSelectTechnician}
                        usedCapacity={usedCapacity}
                        capacityReached={capacityReached}
                        availableTechnicians={availableTechnicians}
                    />
                </div>

                <div className="column">
                    <AssignedScheduleAlreadyAssignedTrigram assignedSchedule={assignedSchedule} />
                    <AssignedScheduleNonSamePlanningModeTrigrams assignedSchedule={assignedSchedule} onTechnicianClick={onSelectTechnician} availableTechnicians={availableTechnicians}/>
                </div>
            </div>
        </div>
    )
}
